




































































































@import '~variables'

.floor-select
  z-index 505
  font-size 3rem

.floor-container
  z-index 1
  .floor
    overflow hidden
    height auto
    pointer-events auto !important
