








































































@import '~variables'

svg text
  font-size 100% !important
  pointer-events none
  user-select none

.floor
  position absolute
  margin auto
  width 100%
  height 100%
  text-align center
  transition:
    top 1s cubic-bezier(0.02, 0.63, 0.31, 1.04),
    left 1s cubic-bezier(0.02, 0.63, 0.31, 1.04),
    z-index 0.2s cubic-bezier(0.02, 0.63, 0.31, 1.04)
  pointer-events none
  &.active
    svg
      pointer-events all
  &.drag
    svg
      cursor move
  &--zoomed
    svg
      .space-name
        display initial !important
        transition all 100ms ease
        user-select none
  &--hide
    display none
  svg
    overflow visible !important
    margin auto
    transition transform 0.2s ease
    transform rotateX(-60deg) rotateY(0deg) rotateZ(-45deg)
    @media screen and (max-width: 500px)
      transition transform 0.1s linear
    .space
      transition fill 0.2s ease
      fill $silver
      stroke $dark
      stroke-width 0.2
      &.selected
        opacity 1
        fill white !important
    .space-name
      display none
    .wall
      pointer-events none
      fill #333
      stroke none
    .door
      fill $silver
      stroke black
      stroke-width 0.5px
    .window
      fill darken($light, 5%)
      stroke @fill
  .canvas
    position absolute
    top 0
    left 0
    opacity 0.7
    transition transform 0.2s ease
